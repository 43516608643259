import "./style.scss";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { addCircleOutline, removeCircleOutline } from "ionicons/icons";
import { FC, useMemo } from "react";

import { IRateStepperProps, NegotiationRateRole } from "./interfaces";

export const RateStepper: FC<IRateStepperProps> = ({
  proposedBy,
  currentRate,
  increaseRate,
  decreaseRate,
  upperLimit,
  lowerLimit,
}) => {
  const isProposedByWorker = useMemo(() => {
    return proposedBy === NegotiationRateRole.WORKER;
  }, [proposedBy]);

  const isEndGame = useMemo(
    () => lowerLimit >= upperLimit && proposedBy === NegotiationRateRole.WORKPLACE,
    [lowerLimit, upperLimit, proposedBy]
  );

  const proposedByText = useMemo(() => {
    if (isEndGame) {
      return "Please End or Book";
    } else if (proposedBy === NegotiationRateRole.WORKER) {
      return "Waiting for Workplace";
    }
    return "Propose new rate";
  }, [proposedBy, isEndGame]);

  return (
    <IonGrid className="negotiation-tool">
      <IonRow>
        <IonCol size="12">
          <h1
            data-testid="negotiation-tool-text"
            className={`ion-text-center negotiation-tool-text ${
              isProposedByWorker || isEndGame ? "by-worker" : ""
            }`}
          >
            {proposedByText}
          </h1>
        </IonCol>

        <IonCol size="12" className="negotiation-tool-main">
          <IonButton
            fill="clear"
            color="black"
            onClick={decreaseRate}
            id="rate-stepper-decrease"
            aria-label="Decrease proposed hourly rate"
            disabled={isProposedByWorker || currentRate <= lowerLimit}
          >
            <IonIcon icon={removeCircleOutline} mode="ios" size="large" className="stepper-icon" />
          </IonButton>

          <IonText className="ion-text-center negotiation-tool-amount">
            <p
              style={{
                color: isProposedByWorker || isEndGame ? "#BDBDBD" : "black",
              }}
              id="pay-rate-text"
            >
              {formatDollarsAsUsd(currentRate, { minimumFractionDigits: 0 })}
            </p>
            <p id="hr-text">/hr</p>
          </IonText>

          <IonButton
            fill="clear"
            color="black"
            onClick={increaseRate}
            id="rate-stepper-increase"
            aria-label="Increase proposed hourly rate"
            disabled={isProposedByWorker || currentRate + 1 > upperLimit}
          >
            <IonIcon icon={addCircleOutline} mode="ios" size="large" className="stepper-icon" />
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
