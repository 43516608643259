import "./style.scss";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Grid } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FC, useState } from "react";

import { convertToUtcFormat, extractLocalTimeInformation } from "./helper";
import { ITimeStepperProps } from "./interfaces";

function getDisplayText(timeInUtc, timezone) {
  const { hours, minutes, meridiem } = extractLocalTimeInformation(timeInUtc, timezone);
  return `${hours}:${minutes} ${meridiem.toUpperCase()}`;
}

export const TimeStepper: FC<ITimeStepperProps> = ({
  facilityTimezone,
  currentStart,
  currentEnd,
  newStartTime,
  newEndTime,
  setNewStartTime,
  setNewEndTime,
  disabled,
}) => {
  const [startTime, setStartTime] = useState<Date>(new Date(newStartTime));
  const [endTime, setEndTime] = useState<Date>(new Date(newEndTime));

  const startDisplayText = getDisplayText(
    newStartTime.length ? newStartTime : currentStart,
    facilityTimezone
  );

  const endDisplayText = getDisplayText(
    newEndTime.length ? newEndTime : currentEnd,
    facilityTimezone
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container direction="column">
            <Grid item>
              <Text>Start</Text>
            </Grid>
            <Grid item>
              <Text
                sx={(theme) => ({
                  color: currentStart !== newStartTime ? theme.palette.success.main : "inherit",
                })}
              >
                {startDisplayText}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label="Start Time"
            slotProps={{
              toolbar: {
                ampmInClock: false,
                sx: {
                  "& .MuiTimePickerToolbar-ampmLabel.Mui-selected": {
                    color: "success.main",
                  },
                },
              },
            }}
            value={startTime}
            onChange={(newValue) => {
              if (!isDefined(newValue)) {
                return;
              }

              const newTimeProps = {
                timezone: facilityTimezone,
                hoursIn12hourFormat: newValue.getHours().toString(),
                minutes: newValue.getMinutes().toString(),
                meridiem: newValue.getHours() >= 12 ? "PM" : "AM",
              };

              const { year, month, date } = extractLocalTimeInformation(
                currentStart,
                facilityTimezone
              );

              setNewStartTime(
                convertToUtcFormat({
                  ...newTimeProps,
                  year,
                  month,
                  date,
                })
              );
              setStartTime(newValue);
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="column">
            <Grid item>
              <Text>End</Text>
            </Grid>
            <Grid item>
              <Text
                sx={(theme) => ({
                  color: currentEnd !== newEndTime ? theme.palette.success.main : "inherit",
                })}
              >
                {endDisplayText}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label="End Time"
            slotProps={{
              toolbar: {
                ampmInClock: false,
                sx: {
                  "& .MuiTimePickerToolbar-ampmLabel.Mui-selected": {
                    color: "success.main",
                  },
                },
              },
            }}
            value={endTime}
            onChange={(newValue) => {
              if (!isDefined(newValue)) {
                return;
              }

              const newTimeProps = {
                timezone: facilityTimezone,
                hoursIn12hourFormat: newValue.getHours().toString(),
                minutes: newValue.getMinutes().toString(),
                meridiem: newValue.getHours() >= 12 ? "PM" : "AM",
              };

              const { year, month, date } = extractLocalTimeInformation(
                currentEnd,
                facilityTimezone
              );
              setNewEndTime(
                convertToUtcFormat({
                  ...newTimeProps,
                  year,
                  month,
                  date,
                })
              );
              setEndTime(newValue);
            }}
            disabled={disabled}
            ampmInClock={false}
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};
