import "./style.scss";
import { ModalStatus } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
} from "@mui/material";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { FullScreenDialog, useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { FullScreenDialogTitle } from "@src/appV2/lib/Dialogs/FullScreen/DialogTitle";
import { useCreateNegotiation } from "@src/appV2/Negotiations/api/useCreateNegotiation";
import { useGetNegotiationShiftDetails } from "@src/appV2/Negotiations/api/useGetNegotiatonShiftDetails";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { USER_EVENTS } from "@src/constants/userEvents";
import { openBrowser } from "@src/lib/deprecatedCode";
import { formatDollarsAsUsd } from "@src/utils/currency";
import { findLast } from "lodash";
import moment from "moment-timezone";
import { FC, useEffect, useMemo, useState } from "react";

import { endNegotiation, fetchNegotiationById, proposeNewRate } from "./api";
import { formatTimeNegotiationStartAndEndTime, isTimeNegotiation } from "./helper";
import {
  INegotiationToolProps,
  NegotiationCommitType,
  NegotiationHistoryAction,
  NegotiationMetadata,
  NegotiationRateRole,
  NegotiationStatus,
  NegotiationType,
  RateNegotiationHistoryResponse,
  RateNegotiationSchema,
} from "./interfaces";
import { RateNegotiationShiftDetails } from "./NegotiatingShiftsCard";
import { NegotiationHistory } from "./negotiationHistory";
import { NegotiationToolFooter } from "./negotiationToolFooter";
import { RateStepper } from "./rateStepper";
import { TimeStepper } from "./timeStepper";

const MIN_NEGOTIATION_LIMIT_IN_MINUTES = 15;

export const NegotiationTool: FC<INegotiationToolProps> = ({
  isOpen,
  closeModal,
  shift,
  showCommitModal,
  closeCommitModal,
  workApproved,
  updateShiftRateNegotiation,
  setRequestLoading,
  isLoadingShiftBook,
  negotiationType,
  shiftBookability,
  onBookShift,
}) => {
  const facilityTimezone = shift.facility?.tmz;
  const [currentRate, setCurrentRate] = useState<number>(0);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [newStartTime, setNewStartTime] = useState<string>(shift.start ?? "");
  const [newEndTime, setNewEndTime] = useState<string>(shift.end ?? "");
  const [negotiationHistory, setNegotiationHistory] = useState<RateNegotiationHistoryResponse[]>(
    []
  );

  const ldFlags = useCbhFlags();
  const flagLimits = ldFlags[CbhFeatureFlag.RATE_NEGOTIATION_LIMITS];
  const { userId } = useDefinedWorker();
  const { showErrorToast } = useToast();

  const { mutateAsync: createNegotiation } = useCreateNegotiation();

  const { data: negotiationShiftDetails, isLoading: isGetNegotiationShiftDetailsLoading } =
    useGetNegotiationShiftDetails(
      { shiftId: shift._id ?? "", offerId: shift.offer?.id ?? "" },
      { enabled: isDefined(shift._id) }
    );

  const initialShiftInfo = useMemo(() => {
    return {
      action: NegotiationHistoryAction.INITIAL,
      createdByUserId: shift?.facilityId?.toString() ?? "",
      createdByUserRole: NegotiationRateRole.ADMIN,
      payRate: shift?.rateNegotiation?.initialPayRate || shift?.offer?.pay.value || 0,
      start: shift.start,
      end: shift.end,
    };
  }, [
    shift.end,
    shift?.facilityId,
    shift?.offer?.pay,
    shift?.rateNegotiation?.initialPayRate,
    shift.start,
  ]);

  useEffect(() => {
    const getHistory = () => {
      setPageLoading(true);

      if (!shift.rateNegotiation?._id) {
        setNegotiationHistory([initialShiftInfo]);
        setPageLoading(false);
        return;
      }

      try {
        setNegotiationHistory([initialShiftInfo, ...shift.rateNegotiation.history]);
        setPageLoading(false);
      } catch (error) {
        logError("Error in Rate Negotiation History Fetch", { error });
        setPageLoading(false);
      }
    };

    getHistory();
  }, [initialShiftInfo, shift]);

  useEffect(() => {
    if (!workApproved) {
      return;
    }

    const acceptedHistoryItem = {
      action: NegotiationHistoryAction.ACCEPTED,
      createdByUserId: userId,
      createdByUserRole: NegotiationRateRole.WORKER,
      payRate: currentRate,
      createdAt: moment().toString(),
      start: initialShiftInfo.start,
      end: initialShiftInfo.end,
    };
    const newHistory = [
      initialShiftInfo,
      ...(shift?.rateNegotiation?.history || []),
      acceptedHistoryItem,
    ];

    setNegotiationHistory(newHistory as RateNegotiationHistoryResponse[]);

    const rateNegotiation = {
      ...shift.rateNegotiation,
      history: newHistory,
    } as RateNegotiationSchema;

    const metadata: NegotiationMetadata = {
      ...(rateNegotiation?.metadata || {}),
      lastOffer: acceptedHistoryItem as RateNegotiationHistoryResponse,
    };

    rateNegotiation["metadata"] = metadata;
  }, [currentRate, shift.rateNegotiation, userId, workApproved, initialShiftInfo]);

  /**
   * FIXME - lowerLimit and upperLimit are purely computed props, and do not need `useMemo`
   * Note however, that embedded in this `useMemo` is a call to `setCurrentRate`
   * This needs to be handled carefully since it is an invalid side effect of a memo.
   * Review whether it should be handled in `useEffect`, or as an initial value.
   */
  const { lowerLimit, upperLimit } = useMemo(() => {
    const flagLowerLimit = flagLimits?.Minimum;

    let lastOfferFromHcf = 0;
    let lastOfferByHcp = 0;

    if (
      shift?.rateNegotiation?.metadata.lastOffer.createdByUserRole === NegotiationRateRole.WORKPLACE
    ) {
      lastOfferFromHcf = shift?.rateNegotiation?.metadata?.lastOffer?.payRate ?? 0;
      lastOfferByHcp =
        /**
         * FIXME: This is fragile, since it relies on a physical index offset
         * in the history.
         * This should be based on searching through history for the last HCP offer.
         */
        negotiationHistory[negotiationHistory.length - 2]?.payRate ?? 0;
    } else if (
      shift?.rateNegotiation?.metadata?.lastOffer?.createdByUserRole === NegotiationRateRole.WORKER
    ) {
      lastOfferFromHcf =
        findLast(negotiationHistory, (historyItem: RateNegotiationHistoryResponse) => {
          return historyItem.createdByUserRole === NegotiationRateRole.WORKPLACE;
        })?.payRate ?? 0;

      lastOfferByHcp = shift?.rateNegotiation?.metadata?.lastOffer?.payRate ?? 0;
    }

    const lowerLimit =
      lastOfferFromHcf ||
      initialShiftInfo.payRate ||
      flagLowerLimit ||
      MIN_NEGOTIATION_LIMIT_IN_MINUTES;

    /**
     * FIXME, `useMemo` should not trigger a side effect.
     * There are some insidious bugs related to this.
     * Be aware that this can't be put into a side-effect, and in addition,
     * the initial value needs to be managed.
     *
     */
    setCurrentRate(Number(lowerLimit));

    const flagUpperLimit =
      (lastOfferByHcp && lastOfferByHcp - 1) ||
      (shift?.agentReq && flagLimits?.[shift?.agentReq]) ||
      flagLimits?.Maximum ||
      (shift?.offer?.pay.value && 5 * shift?.offer?.pay.value);

    return {
      lowerLimit: Number(lowerLimit),
      upperLimit: Math.min(
        Number(flagUpperLimit),
        negotiationShiftDetails?.data?.attributes?.maxPayRate ?? Number.MAX_SAFE_INTEGER
      ),
    };
  }, [
    flagLimits,
    shift,
    initialShiftInfo.payRate,
    negotiationHistory,
    negotiationShiftDetails?.data?.attributes?.maxPayRate,
  ]);

  const increasePayRate = () => {
    if (currentRate + 1 > upperLimit) {
      setCurrentRate(upperLimit);
    } else {
      setCurrentRate(Math.floor(currentRate + 1));
    }
  };

  const decreasePayRate = () => {
    if (lowerLimit > currentRate - 1) {
      setCurrentRate(lowerLimit);
    } else {
      setCurrentRate(Math.ceil(currentRate - 1));
    }
  };

  const rateIsDifferent = currentRate !== lowerLimit;
  const startTimeIsDifferent = newStartTime.length && newStartTime !== initialShiftInfo.start;
  const endTimeIsDifferent = newEndTime.length && newEndTime !== initialShiftInfo.end;

  let proposeButtonText = rateIsDifferent
    ? `Propose ${formatDollarsAsUsd(currentRate, { minimumFractionDigits: 0 })} /hr`
    : `Book at ${formatDollarsAsUsd(lowerLimit, { minimumFractionDigits: 0 })}/hr`;

  if (isTimeNegotiation(negotiationType)) {
    const proposeNewTimeText =
      (startTimeIsDifferent || endTimeIsDifferent) && facilityTimezone
        ? formatTimeNegotiationStartAndEndTime({
            start: newStartTime,
            end: newEndTime,
            timezone: facilityTimezone,
          })
        : "new time";
    proposeButtonText =
      (startTimeIsDifferent || endTimeIsDifferent) && negotiationHistory.length === 1
        ? `Propose ${proposeNewTimeText}`
        : `Book shift at posted time`;
  }

  const workerLastProposal = useMemo(
    () =>
      negotiationHistory?.length &&
      negotiationHistory[negotiationHistory.length - 1]?.createdByUserRole ===
        NegotiationRateRole.WORKER
        ? negotiationHistory[negotiationHistory.length - 1].payRate
        : 0,
    [negotiationHistory]
  );

  const handleProposal = async () => {
    try {
      setRequestLoading(true);
      let negotiation: RateNegotiationSchema;
      const negotiationId = shift?.rateNegotiation?._id;
      const newProposal = isTimeNegotiation(negotiationType)
        ? { start: newStartTime, end: newEndTime }
        : { payRate: currentRate };
      if (negotiationId) {
        const data = await proposeNewRate({
          shiftId: shift?._id ?? "",
          negotiationId,
          ...newProposal,
        });
        ({ negotiation } = data);
      } else {
        const { rateNegotiationId } = await createNegotiation({
          shiftId: shift?._id ?? "",
          offerId: shift?.offer?.id ?? "",
          ...newProposal,
        });

        const data = await fetchNegotiationById(rateNegotiationId);
        ({ negotiation } = data);
      }

      let eventName: string;
      let extraPayload: object;
      if (isTimeNegotiation(negotiation.type)) {
        eventName = USER_EVENTS.NEGOTIATION_TIME_PROPOSED;
        extraPayload = {
          proposed_start: newStartTime,
          proposed_end: newEndTime,
          initial_start: initialShiftInfo.start,
          initial_end: initialShiftInfo.end,
        };
      } else {
        eventName = USER_EVENTS.NEGOTIATION_RATE_PROPOSED;
        extraPayload = {
          proposed_rate: currentRate,
          previous_rate: negotiationHistory[negotiationHistory.length - 1].payRate,
        };
      }
      logEvent(eventName, {
        shiftId: shift._id,
        negotiationId: negotiation._id,
        proposals:
          negotiationHistory.filter(
            (historyItem) =>
              historyItem.action === NegotiationHistoryAction.PROPOSED ||
              historyItem.action === NegotiationHistoryAction.STARTED
          ).length + 1,
        ...extraPayload,
      });

      setNegotiationHistory([initialShiftInfo, ...negotiation.history]);
      const metadata = {
        ...(negotiation?.metadata || {}),
        lastOffer: negotiation.history[negotiation.history.length - 1],
      };
      negotiation["metadata"] = metadata;
      updateShiftRateNegotiation(negotiation);
      closeCommitModal();
      setRequestLoading(false);
    } catch (error) {
      setRequestLoading(false);
      logError("Error In Proposal", { error });
      showErrorToast("Error In Proposal");
    }
  };

  const handleEndNegotiation = async () => {
    const negotiationId = shift?.rateNegotiation?._id;
    if (!negotiationId) {
      throw Error("Negotiation Id is required");
    }
    setRequestLoading(true);
    try {
      await endNegotiation(negotiationId);
      const { negotiation } = await fetchNegotiationById(negotiationId);

      logEvent(USER_EVENTS.NEGOTIATION_ENDED, {
        shiftId: shift._id,
        negotiationId: negotiationId,
        proposals: negotiationHistory.filter(
          (historyItem) =>
            historyItem.action === NegotiationHistoryAction.PROPOSED ||
            historyItem.action === NegotiationHistoryAction.STARTED
        ).length,
      });

      setNegotiationHistory([
        ...(negotiationHistory || []),
        {
          action: NegotiationHistoryAction.REJECTED,
          createdByUserId: String(userId),
          createdByUserRole: NegotiationRateRole.WORKER,
          payRate: currentRate,
          createdAt: moment().toString(),
        },
      ]);

      const metadata = {
        ...(negotiation?.metadata || {}),
        lastOffer: negotiation.history[negotiation.history.length - 1],
      };
      negotiation["metadata"] = metadata;

      updateShiftRateNegotiation(negotiation);
      closeCommitModal();
      setRequestLoading(false);
    } catch (error) {
      setRequestLoading(false);
      logError("Error Ending Negotiation", { error: JSON.stringify((error as Error)?.stack) });
      showErrorToast("Error Ending Negotiation");
    }
  };

  const isWorkerAllowedToPropose =
    !workApproved &&
    (!shift?.rateNegotiation || shift?.rateNegotiation?.status === NegotiationStatus.OPEN);

  const isWorkerProposing =
    rateIsDifferent ||
    ((Boolean(startTimeIsDifferent) || Boolean(endTimeIsDifferent)) &&
      negotiationHistory.length === 1);

  const openRateNegotiationSupportLink = () => {
    const { [CbhFeatureFlag.SUPPORT_LINKS]: supportLinks } = ldFlags;
    const supportLink =
      negotiationType === NegotiationType.TIME
        ? supportLinks?.TIME_NEGOTIATION_WORKER || DEFAULT_SUPPORT_LINKS.TIME_NEGOTIATION_WORKER
        : supportLinks?.RATE_NEGOTIATION_WORKER || DEFAULT_SUPPORT_LINKS.RATE_NEGOTIATION_WORKER;
    openBrowser(supportLink);
    return;
  };

  return (
    <FullScreenDialog
      modalState={{
        modalIsOpen: isOpen,
        modalIsClosed: !isOpen,
        openModal: () => {},
        closeModal: () => closeModal(),
        modalStatus: ModalStatus.OPEN,
        setModalStatus: () => {},
      }}
    >
      <FullScreenDialogTitle
        onClose={() => {
          closeModal();
        }}
      >
        {negotiationType === NegotiationType.TIME
          ? "Propose an alternative time"
          : "Shift Rate Negotiation"}
      </FullScreenDialogTitle>
      <DialogContent dividers>
        <RateNegotiationShiftDetails shift={shift} />
        {pageLoading || isGetNegotiationShiftDetailsLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {shift?.rateNegotiation && (
              <IconButton
                edge="end"
                color="inherit"
                onClick={openRateNegotiationSupportLink}
                data-testid="rn-information-icon"
              >
                <InfoIcon />
              </IconButton>
            )}
            <NegotiationHistory
              facilityTimezone={facilityTimezone}
              negotiationType={negotiationType}
              history={negotiationHistory}
              rateNegotiationStatus={shift?.rateNegotiation?.status}
              workApproved={workApproved}
              openRateNegotiationSupportLink={openRateNegotiationSupportLink}
            />
            {isWorkerAllowedToPropose &&
              (isTimeNegotiation(negotiationType) ? (
                initialShiftInfo.start &&
                initialShiftInfo.end &&
                facilityTimezone && (
                  <Box py={2}>
                    <TimeStepper
                      facilityTimezone={facilityTimezone}
                      currentStart={initialShiftInfo.start}
                      currentEnd={initialShiftInfo.end}
                      newStartTime={newStartTime}
                      newEndTime={newEndTime}
                      setNewStartTime={setNewStartTime}
                      setNewEndTime={setNewEndTime}
                      disabled={negotiationHistory.length > 1}
                    />
                  </Box>
                )
              ) : (
                <RateStepper
                  proposedBy={
                    shift.rateNegotiation?.metadata?.lastOffer?.createdByUserRole ||
                    NegotiationRateRole.NEW_PROPOSAL
                  }
                  currentRate={workerLastProposal || currentRate}
                  increaseRate={increasePayRate}
                  decreaseRate={decreasePayRate}
                  upperLimit={upperLimit}
                  lowerLimit={lowerLimit}
                />
              ))}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ flexDirection: "column" }} disableSpacing>
        <NegotiationToolFooter
          shift={shift}
          negotiationHistory={negotiationHistory}
          workApproved={workApproved}
          closeModal={closeModal}
          isWorkerProposing={isWorkerProposing}
          showCommitModal={showCommitModal}
          pageLoading={pageLoading}
          isLoadingShiftBook={isLoadingShiftBook}
          proposeButtonText={proposeButtonText}
          onClickProposeButton={() => {
            const formattedTimeText = facilityTimezone
              ? formatTimeNegotiationStartAndEndTime({
                  start: newStartTime,
                  end: newEndTime,
                  timezone: facilityTimezone,
                })
              : "new";
            showCommitModal({
              isOpen: true,
              onCommit: handleProposal,
              ...(isTimeNegotiation(negotiationType)
                ? {
                    modalType: NegotiationCommitType.PROPOSE_NEW_TIME,
                    formattedTimeText,
                  }
                : {
                    modalType: NegotiationCommitType.PROPOSE_NEW_RATE,
                    rate: currentRate,
                  }),
            });
          }}
          handleEndNegotiation={handleEndNegotiation}
          shiftBookability={shiftBookability}
          bookabilityNegotiationMetadata={{
            negotiationId: shift?.rateNegotiation?._id ?? "",
            bookRate: lowerLimit,
          }}
          onBookShift={onBookShift}
        />
      </DialogActions>
    </FullScreenDialog>
  );
};
